import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControl,
	InputLabel,
	FormHelperText,
} from '@material-ui/core';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteGroup,
	getGroupById,
	postGroup,
	updateGroup,
} from '../../../../api/group';
import { useSkeleton } from '../../../hooks/useSkeleton';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../utils';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getGames } from '../../../../api/game';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyGroup() {
	return {
		fullName: '',
		game: null,
		image: '',
		tableNum: '',
	};
}

export default function EditGroupsPage() {
	const [group, setGroup] = useState(getEmptyGroup());
	const [games, setGames] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewImage, setOpenPreviewImage] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const groupId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getGames().then((res) => {
			if (res.status === 200) {
				setGames(res.data);
			}
		});
		if (!groupId) {
			disableLoadingData();
			return;
		}
		getGroupById(groupId)
			.then((res) => {
				if (res.status === 200) {
					setGroup(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get group.' });
				history.push('/groups');
			});
	}, [groupId, disableLoadingData, history]);

	function saveGroup() {
		if (!groupId) {
			postGroup(group, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Group successfully created.',
						});
						history.push('/groups');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save group.' });
				});
		} else {
			updateGroup(groupId, group, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/groups');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setGroup({ ...group, [element]: event.target.value });
	};

	const handleChangeGames = (new_selected) => {
		setGroup((prevGroup) => ({
			...prevGroup,
			game: new_selected?.value || null,
			tableNum: '',
		}));
	};

	const handleChangeTables = (new_selected) => {
		setGroup((prevGroup) => ({
			...prevGroup,
			tableNum: new_selected?.value || null,
		}));
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit group'></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label='Full name'
							value={group.fullName}
							onChange={handleChange('fullName')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>

						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Content type
							</InputLabel>
							<Select
								options={games?.map((game) => ({
									label: game.fullName,
									value: game._id,
								}))}
								value={games
									?.filter((game) => game._id === group.game)
									.map((game) => ({
										label: game.fullName,
										value: game._id,
									}))}
								onChange={handleChangeGames}
								isClearable
								isSearchable
								placeholder={`Select game...`}
								className='mb-3 mt-3'
							/>
							<FormHelperText>Select a Game</FormHelperText>
							{group.game && (
								<>
									<Select
										options={Array.from(
											{
												length: parseInt(
													games?.filter((item) => item._id === group.game)[0]
														.numTables
												),
											},
											(_, index) => (index + 1).toString()
										).map((num) => ({
											label: num,
											value: num,
										}))}
										value={{ label: group.tableNum, value: group.tableNum }}
										onChange={handleChangeTables}
										isClearable
										isSearchable
										placeholder={`Select table...`}
										className='mb-3 mt-3'
									/>
									<FormHelperText>Select a Table</FormHelperText>
								</>
							)}
						</FormControl>
						<label className='mt-4' htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || group.image !== ''
									? 'Change image'
									: 'Upload image'}
							</Button>
						</label>
						{(selectedImage || group.image !== '') && (
							<>
								<Button
									onClick={() => setOpenPreviewImage(true)}
									style={{ marginRight: '15px' }}
									variant='outlined'>
									{'Preview image'}
								</Button>
								<PreviewDialog
									title={'Preview image'}
									open={openPreviewImage}
									setOpen={setOpenPreviewImage}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${group.image}`
									}
								/>
							</>
						)}
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/groups')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Back
				</Button>
				<Button
					onClick={() => saveGroup()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Save group
				</Button>
				{groupId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(true)}
								variant='outlined'
								color='secondary'>
								Delete group
							</Button>
						</MuiThemeProvider>

						<ConfirmDialog
							title={'Are you sure you want to delete this group?'}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteGroup(groupId)
									.then((res) => {
										if (res.status === 204) {
											alertSuccess({
												title: 'Deleted!',
												customMessage: 'Group deleted successfully',
											});
											history.push('/groups');
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage: 'Could not delete economic sector.',
										});
									});
							}}
						/>
					</>
				)}
			</>
		);
}
