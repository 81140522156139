import { API, /* baseClient, */ authClient } from "../../utils/index";

export const getSurveys = (query) => {
	return authClient().get(`${API}/survey${query || ""}`);
};

export const getSurveyById = (id) => {
	return authClient().get(`${API}/survey/${id}`);
};

export const postSurvey = (survey) => {
	return authClient().post(`${API}/survey`, survey);
};

export const updateSurvey = (id, survey) => {
	return authClient().put(`${API}/survey/${id}`, survey);
};

export const changeStatusSurvey = async (id, active) => {
	return authClient().put(`${API}/survey/change-status/${id}`, { active });
};
