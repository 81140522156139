import { /* baseClient, */ authClient, API } from '../../utils/index'

// Get all participants
export const getParticipants = () => {
  return authClient().get(`${API}/participant`)
}

// Get participant by id
export const getParticipantById = (id) => {
  return authClient().get(`${API}/participant/${id}`)
}

// Delete participant
export const deleteParticipant = (id) => {
  return authClient().delete(`${API}/participant/${id}`)
}

// Create participant
export const postParticipant = (participant) => {
  return authClient().post(`${API}/participant`, participant)
}

// Update participant
export const updateParticipant = (id, participant) => {
  return authClient().put(`${API}/participant/${id}`, participant)
}
