import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle,
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getGames, deleteGame, setDefaultGame } from '../../../../api/game';
import { Button, Tooltip, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import IpadIcon from '@material-ui/icons/TabletMacOutlined';
import PlayIcon from '@material-ui/icons/PlayArrowRounded';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { FRONT_URL } from '../../../../utils';

function getData(games) {
	let data = [];
	for (let i = 0; i < games.length; ++i) {
		const elem = {};
		elem.name = games[i].fullName;
		elem.numTables = games[i].numTables;
		elem.createdAt = games[i].createdAt;
		elem.default = games[i].default;
		elem.data = games[i];
		data = data.concat(elem);
	}
	return data;
}

export default function GamesPage() {
	const [data, setData] = useState([]);
	const [gameId, setGameId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [copiedLink, setCopiedLink] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				{!cell.default && (
					<Tooltip title='Set Default'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setGameId(cell._id);
								setOpenConfirmDialog(1);
							}}>
							<PlayIcon />
						</Button>
					</Tooltip>
				)}
				<Tooltip title='Ranking'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/ranking-game/' + cell._id)}>
						<EmojiEventsIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-game/' + cell._id)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Delete'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setGameId(cell._id);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Play'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							navigator.clipboard.writeText(`${FRONT_URL}?game=${cell._id}`);
							setCopiedLink(true);
						}}>
						<IpadIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	function defaultFormatter(cell) {
		return cell ? <div className='default-label'>default</div> : '';
	}

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{ dataField: 'numTables', text: 'Num. tables', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'default', text: 'Default', formatter: defaultFormatter },
		{
			dataField: 'data',
			text: '',
			formatter: buttonFormatter,
			align: 'end',
		},
	];

	useEffect(() => {
		getGames()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get games.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Games list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-game')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={'Are you sure you want to remove this game?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteGame(gameId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Game removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove game.',
									});
								});
						}}
					/>
					<ConfirmDialog
						title='Are you sure you want to set this game as default?'
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							setDefaultGame(gameId)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: 'Success!',
											customMessage: `Game activated`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Game could not be activated.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
			<Snackbar
				open={copiedLink}
				autoHideDuration={6000}
				onClose={() => setCopiedLink(false)}>
				<MuiAlert severity='info' sx={{ width: '100%' }}>
					Game link copied
				</MuiAlert>
			</Snackbar>
		</>
	);
}
