import { /* baseClient, */ authClient, API } from '../../utils/index';

// Get all clinic cases
export const getClinicCases = () => {
	return authClient().get(`${API}/cliniccase`);
};

// Update clinic case
export const updateClinicCase = (id, cliniccase) => {
	return authClient().put(`${API}/cliniccase/${id}`, cliniccase);
};
