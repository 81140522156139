import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import Table, { dateFormatter } from '../../../components/tables/table';
import { getClinicCases } from '../../../../api/cliniccase';
import { alertError } from '../../../../utils/logger';

function getData(cliniccases) {
	let data = [];
	for (let i = 0; i < cliniccases.length; ++i) {
		const elem = {};
		elem.name = cliniccases[i].fullName;
		elem.createdAt = cliniccases[i].createdAt;
		elem.id = cliniccases[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function ClinicCasesPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getClinicCases()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get clinic cases.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Clinic Cases list'></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
