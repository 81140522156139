import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	timeFormatter,
	pointsFormatter,
} from '../../../components/tables/table';
import { getGroupsByGame, getGroupsByGameCase1 } from '../../../../api/group';
import { alertError } from '../../../../utils/logger';
import { useParams } from 'react-router-dom';
import RankingDialog from '../../../components/dialogs/RankingDialog';

function getData(groups) {
	let data = [];
	for (let i = 0; i < groups.length; ++i) {
		const elem = {};
		elem.group = groups[i].fullName;
		elem.tableNum = groups[i].tableNum;
		elem.score = groups[i].scoreCase1 + groups[i].scoreCase2;
		elem.time = groups[i].timeCase1 + groups[i].timeCase2;
		elem.id = groups[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function RankingGamesPage() {
	const [data, setData] = useState([]);
	const [case1, setCase1] = useState([]);
	const [dataList, setDataList] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openRankingDialog, setOpenRankingDialog] = useState(null);
	const gameId = useParams().id;

	const columns = [
		{
			dataField: 'group',
			text: 'Group',
			headerAlign: 'center',
			align: 'center',
			sort: true,
		},
		{
			dataField: 'tableNum',
			text: 'Table num.',
			headerAlign: 'center',
			align: 'center',
			sort: true,
		},
		{
			dataField: 'score',
			text: 'Score',
			headerAlign: 'center',
			align: 'center',
			formatter: pointsFormatter,
			sort: true,
		},
		{
			dataField: 'time',
			text: 'Time',
			headerAlign: 'center',
			align: 'center',
			formatter: timeFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getGroupsByGameCase1(gameId, '1').then((res) => {
			if (res.status === 200) {
				setCase1(res.data);
			}
		});
		getGroupsByGame(gameId)
			.then((res) => {
				if (res.status === 200) {
					setDataList(getData(res.data));
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get ranking.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Ranking'>
					<CardHeaderToolbar>
						<button
							className={
								'btn btn-primary font-weight-bold my-3 align-self-center mr-3'
							}
							onClick={() => setOpenRankingDialog(1)}>
							Ranking caso Dra. Mena
						</button>
						<button
							className={
								'btn btn-primary font-weight-bold my-3 align-self-center mr-3'
							}
							onClick={() => setOpenRankingDialog(2)}>
							Ranking caso Dr. Castellví
						</button>
						<button
							className={
								'btn btn-primary font-weight-bold my-3 align-self-center'
							}
							onClick={() => setOpenRankingDialog(3)}>
							Ranking Final
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={dataList} columns={columns} />
				</CardBody>
			</Card>
			<RankingDialog
				title={
					!openRankingDialog || openRankingDialog === 3
						? 'Ranking final'
						: `Ranking caso clínico ${openRankingDialog}`
				}
				open={openRankingDialog}
				setOpen={setOpenRankingDialog}
				data={openRankingDialog === 1 ? case1 : data}
			/>
		</>
	);
}
