import { Button, TextField, createMuiTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	getSurveyById,
	postSurvey,
	updateSurvey,
} from "../../../../api/survey";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptySurvey() {
	return {
		title: "",
		active: true,
	};
}

export default function EditSurveysPage() {
	const [survey, setSurvey] = useState(getEmptySurvey());
	const surveyId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!surveyId) {
			disableLoadingData();
			return;
		}
		getSurveyById(surveyId)
			.then((res) => {
				if (res.status === 200) {
					setSurvey(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get survey.",
				});
				history.push("/surveys");
			});
	}, [surveyId, disableLoadingData, history]);

	function saveSurvey() {
		if (!surveyId) {
			postSurvey(survey)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/surveys");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		} else {
			updateSurvey(surveyId, survey)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/surveys");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setSurvey({ ...survey, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Editar pregunta"></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Título"
							value={survey.title}
							onChange={handleChange("title")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push("/surveys")}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Atrás
				</Button>
				<Button
					onClick={() => saveSurvey()}
					variant="outlined"
					color="primary"
					style={{ marginRight: "20px" }}
				>
					Guardar pregunta
				</Button>
			</>
		);
}
