import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter
} from '../../../components/tables/table';
import { Button, Tooltip } from '@material-ui/core';
import { getScenes } from '../../../../api/scene';
import { alertError } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import EqualizerIcon from '@material-ui/icons/Equalizer';

function getData(scenes) {
	let data = [];
	for (let i = 0; i < scenes.length; ++i) {
		const elem = {};
		elem.name = scenes[i].fullName;
		elem.cliniccase = scenes[i].cliniccase.fullName;
		elem.createdAt = scenes[i].createdAt;
		elem.id = scenes[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function ScenesPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Quiz'>
					<Button onClick={() => history.push('/scenario/' + cell)}>
						<EqualizerIcon style={{ marginRight: '10px' }} />
						{'Answers'}
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{ dataField: 'cliniccase', text: 'Clinic Case', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			align: 'center',
			headerAlign: 'center',
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: 'id',
			text: 'Statistics',
			align: 'center',
			headerAlign: 'center',
			formatter: buttonFormatter,
		},
	];

	useEffect(() => {
		getScenes()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get scenarios.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Scenarios list'></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
