import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControl,
	InputLabel,
	FormHelperText
} from '@material-ui/core'
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom'
import { deleteParticipant, getParticipantById, postParticipant, updateParticipant } from '../../../../api/participant'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getGroups } from '../../../../api/group'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptyParticipant() {
  return {
    fullName: '',
		group: null
  }
}

export default function EditParticipantsPage() {
  const [participant, setParticipant] = useState(getEmptyParticipant())
	const [groups, setGroups] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const participantId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
		getGroups()
			.then((res) => {
				if (res.status === 200) {
					setGroups(res.data);
				}
			})
    if (!participantId) {
      disableLoadingData()
      return
    }
    getParticipantById(participantId).then(res => {
      if (res.status === 200) {
        setParticipant(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get participant.' })
      history.push('/participants')
    })
  }, [participantId, disableLoadingData, history])

  function saveParticipant() {
    if (!participantId) {
      postParticipant(participant).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Participant successfully created.' })
          history.push('/participants')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save participant.' })
      })
    } else {
      updateParticipant(participantId, participant).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/participants')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setParticipant({ ...participant, [element]: event.target.value })
  }
	const handleChangeGroups = (new_selected) => {
		setParticipant((prevParticipant) => ({
			...prevParticipant,
			group: new_selected?.value || null,
		}));
	};

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit participant'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={participant.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
					<FormControl style={{ width: '100%' }}>
						<InputLabel id='demo-simple-select-standard-label'>
							Content type
						</InputLabel>
						<Select
							options={groups?.map((group) => ({
								label: group.fullName,
								value: group._id,
							}))}
							value={groups
								?.filter((group) => group._id === participant.group)
								.map((group) => ({
									label: group.fullName,
									value: group._id,
								}))}
							onChange={handleChangeGroups}
							isClearable
							isSearchable
							placeholder={`Select group...`}
							className='mb-3 mt-3'
						/>
					<FormHelperText>Select a Group</FormHelperText>
					</FormControl>
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/participants')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveParticipant()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save participant
      </Button>
      {participantId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete participant
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this participant?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteParticipant(participantId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Participant deleted successfully' })
                history.push('/participants')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete economic sector.' })
            })
          }}
        />
      </>}
    </>
  );
}
