import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter
} from '../../../components/tables/table';
import { Button, Tooltip } from '@material-ui/core';
import { getQuizzes } from '../../../../api/quiz';
import { alertError } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import EqualizerIcon from '@material-ui/icons/Equalizer';

function getData(quizzes) {
	let data = [];
	for (let i = 0; i < quizzes.length; ++i) {
		if (!quizzes[i].supertest) {
			const elem = {};
			elem.name = quizzes[i].fullName;
			elem.scenario = quizzes[i].scenario.fullName;
			elem.createdAt = quizzes[i].createdAt;
			elem.id = quizzes[i]._id;
			data = data.concat(elem);
		}
	}
	return data;
}

export default function QuizzesPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Quiz'>
					<Button onClick={() => history.push('/quiz/' + cell)}>
						<EqualizerIcon style={{ marginRight: '10px' }} />
						{'Answers'}
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{ dataField: 'scenario', text: 'Scenario', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			align: 'center',
			headerAlign: 'center',
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: 'id',
			text: 'Statistics',
			align: 'center',
			headerAlign: 'center',
			formatter: buttonFormatter,
		},
	];

	useEffect(() => {
		getQuizzes()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get quizzes.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Quizzes list'></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
