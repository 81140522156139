import { /* baseClient, */ authClient, API } from '../../utils/index';

// Get all games
export const getGames = () => {
	return authClient().get(`${API}/game`);
};

// Get game by id
export const getGameById = (id) => {
	return authClient().get(`${API}/game/${id}`);
};

// Delete game
export const deleteGame = (id) => {
	return authClient().delete(`${API}/game/${id}`);
};

// Create game
export const postGame = (game) => {
	return authClient().post(`${API}/game`, game);
};

// Update game
export const updateGame = (id, game) => {
	return authClient().put(`${API}/game/${id}`, game);
};

// Set default game
export const setDefaultGame = (id) => {
	return authClient().put(`${API}/game/set-default/${id}`);
};
