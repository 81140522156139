import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle,
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getUsers, deleteUser } from '../../../../api/user';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';

function getData(admins) {
	let data = [];
	for (let i = 0; i < admins.length; ++i) {
		if (admins[i].role === 'admin' || admins[i].role === 'moderator') {
			const elem = {};
			elem.name = admins[i].fullName;
			elem.role = admins[i].role;
			elem.email = admins[i].email;
			elem.createdAt = admins[i].createdAt;
			elem.id = admins[i]._id;
			data = data.concat(elem);
		}
	}
	return data;
}

export default function AdminsPage() {
	const [data, setData] = useState([]);
	const [adminId, setAdminId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-admin/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Delete'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setAdminId(cell);
							setOpenConfirmDialog(true);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Administrator', sort: true },
		{ dataField: 'role', text: 'Role', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: 'id',
			text: '',
			formatter: buttonFormatter,
			searchable: false,
		},
	];

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get administrators.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Administrators list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-admin')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={'Are you sure you want to remove this admin?'}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteUser(adminId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Admin removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove admin.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
