import { /* baseClient, */ authClient, API } from '../../utils/index';

// Get all quizzes
export const getQuizzes = () => {
	return authClient().get(`${API}/quiz`);
};

// Get the specified quiz
export const getQuizById = (id) => {
	return authClient().get(`${API}/quiz/${id}`);
};
