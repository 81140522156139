import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React from "react";
import Table from "../tables/table";

const TableDialog = (props) => {
	const { title, subtitle, open, setOpen, data, columns } = props;

	const tableColumns = columns
		? columns
		: [
				{
					dataField: "title",
					text: "Título",
					sort: true,
				},
				{
					dataField: "score",
					text: "Puntuación",
					headerAlign: "center",
					align: "center",
					sort: true,
				},
		  ];

	return (
		<Dialog
			fullWidth={true}
			maxWidth="lg"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				{subtitle && (
					<>
						<div style={{ fontSize: "16px" }}>{subtitle}</div>
						<br />
						<br />
					</>
				)}
				{!data || !data.length ? (
					<p>{"No hay respuestas disponibles"}</p>
				) : (
					<Table data={data} columns={tableColumns} />
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					variant="outlined"
				>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default TableDialog;
