import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls';
import { alertError, alertSuccess } from '../../utils/logger';
import { getGameById, getGames, updateGame } from '../../api/game';
import { getGroups, getGroupsByGame } from '../../api/group';
import { updateClinicCase } from '../../api/cliniccase';
import { getParticipants } from '../../api/participant';
import { shallowEqual, useSelector } from 'react-redux';
import { ResultsTable } from '../components/tables/table';
import { Button, Tooltip } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';

function getAdminData(games, groups, participants) {
	let data = [];
	data.numGames = games.length;
	data.numGroups = groups.length;
	data.numParticipants = participants.length;
	return data;
}

function getModeratorData(groups, participants) {
	let data = [];
	let numParticipants = 0;
	participants.forEach((part) => {
		if (groups.some((gr) => gr._id === part.group)) numParticipants += 1;
	});
	data.numGroups = groups.length;
	data.numParticipants = numParticipants;
	return data;
}

function getClinicCaseInfo(cliniccase) {
	return {
		name: cliniccase.fullName,
		status:
			cliniccase.status === 'running'
				? 'Active'
				: cliniccase.status === 'not-started'
				? 'Not Started'
				: 'Completed',
		data: cliniccase,
	};
}

function getData(game) {
	let data = [];
	game.clinicCases.forEach((elem) => {
		data.push(getClinicCaseInfo(elem));
	});
	return data;
}

export function DashboardPage() {
	const [data, setData] = useState([]);
	const [game, setGame] = useState(null);
	const [active, setActive] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const isModerator = user.game ? true : false;

	const columns = [
		{ dataField: 'name', text: 'Clinic case' },
		{ dataField: 'status', text: 'Status' },
		{ dataField: 'data', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getParticipants()
			.then((resParticipants) => {
				if (resParticipants.status === 200) {
					if (isModerator) {
						getGameById(user.game)
							.then((resGame) => {
								if (resGame.status === 200) {
									getGroupsByGame(user.game)
										.then((resGroups) => {
											if (resGroups.status === 200) {
												setGame(resGame.data);
												setActive(resGame.data.active);
												setData(
													getModeratorData(resGroups.data, resParticipants.data)
												);
												setTableData(getData(resGame.data));
												setRefresh(false);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not get groups.',
											});
										});
								}
							})
							.catch((error) => {
								alertError({
									error: error,
									customMessage: 'Could not get game.',
								});
							});
					} else {
						getGroups()
							.then((resGroups) => {
								if (resGroups.status === 200) {
									getGames()
										.then((resGames) => {
											if (resGames.status === 200) {
												setData(
													getAdminData(
														resGames.data,
														resGroups.data,
														resParticipants.data
													)
												);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not get games.',
											});
										});
								}
							})

							.catch((error) => {
								alertError({
									error: error,
									customMessage: 'Could not get groups.',
								});
							});
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get participants.',
				});
			});
	}, [refresh]);

	function handleGameStatus() {
		setActive((active) => !active);
		setGame({ ...game, active: !active });
		updateGame(game._id, { ...game, active: !active })
			.then((res) => {
				if (res.status === 200) {
					let newTableData = [...tableData];
					let case0 = newTableData[0].data;
					let case1 = newTableData[1].data;
					newTableData[0] = getClinicCaseInfo({
						...case0,
						status: !active ? 'not-started' : 'ended',
					});
					newTableData[1] = getClinicCaseInfo({
						...case1,
						status: !active ? 'not-started' : 'ended',
					});
					setTableData(newTableData);
					updateClinicCase(
						newTableData[0].data._id,
						newTableData[0].data
					).catch(() => {});
					updateClinicCase(
						newTableData[1].data._id,
						newTableData[1].data
					).catch(() => {});
					alertSuccess({
						title: 'Success!',
						customMessage: !game.active ? 'Game Started' : 'Game Ended',
					});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not start the game.',
				});
			});
	}

	function buttonFormatter(cell) {
		return (
			<>
				{cell.status === 'not-started' && (
					<Tooltip title='Start case'>
						<Button onClick={() => handleStartClinicCase(cell)}>
							<PlayArrowIcon style={{ marginRight: '10px' }} />
							{'Start Case'}
						</Button>
					</Tooltip>
				)}
				{cell.status === 'running' && (
					<Tooltip title='End case'>
						<Button onClick={() => handleEndClinicCase(cell)}>
							<StopIcon style={{ marginRight: '10px' }} />
							{'End Case'}
						</Button>
					</Tooltip>
				)}
			</>
		);
	}

	function updateCase(id, cliniccase, activate) {
		updateClinicCase(id, cliniccase)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: 'Success!',
						customMessage: activate
							? 'Clinic Case is now ACTIVE'
							: 'Clinic Case has ENDED',
					});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not end the clinic case.',
				});
			});
	}

	function handleEndClinicCase(cliniccaseToActivate) {
		let newTableData = [...tableData];
		let indexToActivate = newTableData.findIndex(
			(elem) => elem.data._id === cliniccaseToActivate._id
		);
		cliniccaseToActivate.status = 'ended';
		newTableData[indexToActivate] = getClinicCaseInfo(cliniccaseToActivate);
		setTableData(newTableData);
		updateCase(cliniccaseToActivate._id, cliniccaseToActivate, false);
	}

	function handleStartClinicCase(cliniccaseToActivate) {
		if (game.active) {
			let newTableData = [...tableData];
			let indexToActivate = newTableData.findIndex(
				(elem) => elem.data._id === cliniccaseToActivate._id
			);
			let errorMessage = '';
			tableData.forEach((elem, index) => {
				if (index < indexToActivate && elem.data.status === 'running') {
					errorMessage = 'There is already an active case';
				} else if (
					indexToActivate > index &&
					elem.data.status === 'not-started'
				) {
					errorMessage = "You can't skip one case";
				} else if (index > indexToActivate && elem.data.status === 'running') {
					errorMessage = "You can't go back to a past case";
				}
			});
			if (errorMessage.length) {
				alertError({
					error: errorMessage,
					customMessage: "Can't start Clinic Case",
				});
			} else {
				cliniccaseToActivate.status = 'running';
				console.log(indexToActivate);
				newTableData[indexToActivate] = getClinicCaseInfo(cliniccaseToActivate);
				console.log(newTableData[indexToActivate - 1]);
				setTableData(newTableData);
				updateCase(cliniccaseToActivate._id, cliniccaseToActivate, true);
			}
		} else {
			alertError({
				error: 'The game must be started first.',
				customMessage: 'Game not started yet',
			});
		}
	}

	return (
		<>
			<Card>
				<CardHeader
					title={game?.fullName != null ? game?.fullName : 'Statistics'}
				/>
				<CardBody>
					<div>
						<div className='row justify-content-center'>
							{!isModerator && (
								<div className='col-12 col-md-4 text-center my-2'>
									<div class='card'>
										<div class='card-body'>
											<h5 class='card-title'>Total games</h5>
											<div className='contentDash'>{data.numGames}</div>
										</div>
									</div>
								</div>
							)}
							<div className='col-12 col-md-4 text-center my-2'>
								<div class='card'>
									<div class='card-body'>
										<h5 class='card-title'>Total groups</h5>
										<div className='contentDash'>{data.numGroups}</div>
									</div>
								</div>
							</div>
							<div className='col-12 col-md-4 text-center my-2'>
								<div class='card'>
									<div class='card-body'>
										<h5 class='card-title'>Total participants</h5>
										<div className='contentDash'>{data.numParticipants}</div>
									</div>
								</div>
							</div>
						</div>
						{isModerator && (
							<div className='row justify-content-center'>
								<div className='col-12 col-md-3 text-center my-4'>
									<div class='card blueBorder'>
										<div class='card-body'>
											<Tooltip title='Start'>
												<Button
													onClick={() => setOpenConfirmDialog(true)}
													className='contentDashB'>
													{active ? 'End Game' : 'Start Game'}
												</Button>
											</Tooltip>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					{isModerator && <ResultsTable data={tableData} columns={columns} />}
				</CardBody>
				<ConfirmDialog
					title={`Are you sure you want to ${
						game?.active ? 'end' : 'start'
					} this game?`}
					open={openConfirmDialog}
					setOpen={setOpenConfirmDialog}
					onConfirm={handleGameStatus}
				/>
			</Card>
		</>
	);
}
