
export const authActions = {
	loginActionSuccess,
	loginActionFailure,
	logoutAction
}

// LOGIN

export const userConstants = {
	LOGIN_FAILURE: 'login-failure',
	LOGIN_SUCCESS: 'login-success',

	LOGOUT: 'logout'
}

function loginActionFailure ()  {
	return {
		type: userConstants.LOGIN_FAILURE
	}
}
function loginActionSuccess (user)  {
	console.log('login action success: ', userConstants.LOGIN_SUCCESS)
	return {
		type: userConstants.LOGIN_SUCCESS,
		user
	}
}

// LOGOUT

function logoutAction ()  {
	console.log('logout action: ', userConstants.LOGOUT)
	return {
		type: userConstants.LOGOUT
	}
}