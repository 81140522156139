import { userConstants } from './authActions'

const initialState = { loggedIn: false, user: null, distributor: null }

export function authentication (state = initialState, action) {
	switch (action.type){
	case userConstants.LOGIN_SUCCESS:
		return {
			...state,
			loggedIn: true,
			user: action.user.user
		}
	case userConstants.LOGIN_FAILURE:
		return { loggedIn: false }
	case userConstants.LOGOUT:
		return { loggedIn: false }
	default:
		return state
	}
}