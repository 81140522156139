export function getKeyByValue(object, value) {
	return Object.keys(object).find((key) => object[key] === value);
}

export const delay = (n) => new Promise((r) => setTimeout(r, n * 1000));

export function formatScore(group, clinicCase) {
	let score =
		clinicCase === 3
			? group?.scoreCase1 + group?.scoreCase2
			: group?.[`scoreCase${clinicCase}`];
	if (!score) return '0000';
	else if (score < 0) {
		return (score > -10 ? '-00' : score > -100 ? '-0' : '-') + Math.abs(score);
	} else {
		return (
			(score < 10 ? '000' : score < 100 ? '00' : score < 1000 ? '0' : '') +
			score
		);
	}
}

export function formatTime(group, clinicCase) {
	let time =
		clinicCase === 3
			? group?.timeCase1 + group?.timeCase2
			: group?.[`timeCase${clinicCase}`];
	if (!time) return ` 0' 00"`;
	else return `${Math.floor(time / 60)}' ${time - Math.floor(time / 60) * 60}"`;
}
