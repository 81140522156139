import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeaderToolbar,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import Table, {
	pointsFormatter,
	timeFormatter,
} from '../../../components/tables/table';
import { getGroups } from '../../../../api/group';
import { getAnswersByScenario } from '../../../../api/answer';
import { getSceneById } from '../../../../api/scene';
import { alertError } from '../../../../utils/logger';
import { useParams } from 'react-router-dom';

function getData(groups, answers) {
	let data = [];
	groups.forEach((group) => {
		let groupAnswers = answers.filter((answer) => {
			return answer.group === group._id && !answer.hasOwnProperty('correct');
		});
		let result = null;
		if (groupAnswers.length)
			result = groupAnswers?.reduce((a, b) => ({
				score: a.score + b.score,
				time: a.time + b.time,
			}));
		if (result) {
			data = data.concat({
				group: group.fullName,
				pts: result.score,
				time: result.time,
			});
		}
	});

	return data;
}

/*const renderRange = (scenario) => {
	if (scenario?.range.length > 1)
		return `<p>
				Score Range
				<b>${scenario?.range[0] + ' / ' + scenario?.range[1]}</b>
			</p>`;

	return `<p>
				Possible Score
				<b>${scenario?.range[0]}</b>
			</p>`;
};*/

export default function ViewScenePage() {
	const [data, setData] = useState([]);
	const [clinicalcase, setClinicalcase] = useState('');
	const [scenario, setScenario] = useState();
	const [refresh, setRefresh] = useState(false);
	const sceneId = useParams().id;

	const defaultSorted = [
		{
			dataField: 'pts',
			order: 'desc',
		},
	];

	const columns = [
		{ dataField: 'group', text: 'Group' },
		{
			dataField: 'pts',
			text: 'Points',
			align: 'center',
			headerAlign: 'center',
			formatter: pointsFormatter,
			sort: true,
		},
		{
			dataField: 'time',
			text: 'Time',
			align: 'center',
			headerAlign: 'center',
			formatter: timeFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getSceneById(sceneId)
			.then((res) => {
				if (res.status === 200) {
					setScenario(res.data);
					setClinicalcase(res.data.cliniccase.fullName);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get the scenario.',
				});
			});

		getAnswersByScenario(sceneId)
			.then((resAnswers) => {
				if (resAnswers.status === 200) {
					getGroups()
						.then((res) => {
							if (res.status === 200) {
								setData(getData(res.data, resAnswers.data));
								setRefresh(false);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: 'Could not get groups.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get groups.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title={`Scenario | ${scenario?.fullName || ''}`}>
					<CardHeaderToolbar>
						<div>
							Clinical Case
							<b> {clinicalcase}</b>
							{scenario?.range.length > 1 ? (
								<p>
									Score Range
									<b> {scenario?.range[0] + ' / ' + scenario?.range[1]}</b>
								</p>
							) : (
								<p>
									Possible Score
									<b> {scenario?.range[0]}</b>
								</p>
							)}
						</div>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} defaultSorted={defaultSorted} />
				</CardBody>
			</Card>
		</>
	);
}
