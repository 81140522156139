import { Button, Tooltip } from "@material-ui/core";
import { Edit, QuestionAnswer, ToggleOff, ToggleOn } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { changeStatusSurvey, getSurveys } from "../../../../api/survey";
import { getSurveyAnswersBySurvey } from "../../../../api/surveyAnswer";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

function getData(surveys) {
	let data = [];
	for (let i = 0; i < surveys.length; ++i) {
		const elem = {};

		elem.title = surveys[i]?.title;
		elem.active = surveys[i]?.active;
		elem.createdAt = surveys[i].createdAt;
		elem.id = surveys[i]._id;

		data = data.concat(elem);
	}
	return data;
}

function getSurveyAnswerData(surveyAnswers) {
	let data = [];
	for (let i = 0; i < surveyAnswers.length; ++i) {
		const elem = {};

		elem.participant =
			surveyAnswers[i]?.participant?.name +
			(surveyAnswers[i]?.participant?.apellidos
				? " " + surveyAnswers[i]?.participant?.apellidos
				: "");
		elem.score = surveyAnswers[i]?.score;
		elem.createdAt = surveyAnswers[i].createdAt;
		elem.id = surveyAnswers[i]._id;

		data = data.concat(elem);
	}
	return data;
}

export default function SurveysPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [selectedSurvey, setSelectedSurvey] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [
		openSurveyAnswersTableDialog,
		setOpenSurveyAnswersTableDialog,
	] = useState(null);
	const [surveyAnswers, setSurveyAnswers] = useState([]);

	const history = useHistory();

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Ver respuestas">
					<Button
						onClick={() => {
							getSurveyAnswersBySurvey(cell)
								.then((res) => {
									if (res.status === 200) {
										setSelectedSurvey(elem);
										setSurveyAnswers(res.data);
										setOpenSurveyAnswersTableDialog(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"No se han podido obtener las respuestas",
									});
								});
						}}
					>
						<QuestionAnswer />
					</Button>
				</Tooltip>
				<Tooltip title="Editar">
					<Button
						onClick={() => history.push("/edit-survey/" + cell)}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title={elem?.active ? "Deshabilitar" : "Habilitar"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedSurvey(elem);
							setOpenConfirmDialog(1);
						}}
					>
						{elem?.active ? (
							<ToggleOff />
						) : (
							<ToggleOn style={{ color: "red" }} />
						)}
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: "title",
			text: "Título",
			sort: true,
		},
		{
			dataField: "createdAt",
			text: "Creado el",
			align: "center",
			headerAlign: "center",
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: "id",
			text: "",
			align: "center",
			headerAlign: "center",
			formatter: buttonFormatter,
		},
	];

	const surveyAnswersColumns = [
		{
			dataField: "participant",
			text: "Participante",
			sort: true,
		},
		{
			dataField: "score",
			text: "Puntuación",
			align: "center",
			headerAlign: "center",
			sort: true,
		},
		{
			dataField: "createdAt",
			text: "Creado el",
			align: "center",
			headerAlign: "center",
			formatter: dateFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getSurveys("?getDisabled=true")
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get surveys.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Listado de preguntas">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-survey")}
						>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
			<ConfirmDialog
				title={"¿Estás seguro que quieres deshabilitar esta pregunta?"}
				open={openConfirmDialog === 1}
				setOpen={setOpenConfirmDialog}
				onConfirm={() => {
					changeStatusSurvey(
						selectedSurvey.id,
						!selectedSurvey?.active
					)
						.then((res) => {
							if (res.status === 200) {
								alertSuccess({
									title: "¡Guardado!",
									customMessage:
										"Cambios guardados con éxito",
								});
								setRefresh(true);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									"No se pudo actualizar la pregunta",
							});
						});
				}}
			/>
			<TableDialog
				title={
					<div>
						Respuestas:{" "}
						<span style={{ fontSize: "16px", fontWeight: "400" }}>
							{selectedSurvey?.title}
						</span>
					</div>
				}
				subtitle={
					surveyAnswers?.length > 0 && (
						<div className="d-flex justify-content-end">
							<span>
								<strong>Puntuación media: </strong>
								{(
									surveyAnswers.reduce(
										(acc, curr) => acc + curr.score,
										0
									) / surveyAnswers.length
								).toFixed(1)}
							</span>
						</div>
					)
				}
				open={openSurveyAnswersTableDialog}
				setOpen={setOpenSurveyAnswersTableDialog}
				data={getSurveyAnswerData(surveyAnswers)}
				columns={surveyAnswersColumns}
			></TableDialog>
		</>
	);
}
