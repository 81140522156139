import React from 'react';
import { SERVER_URL } from '../../../utils';
import { formatScore, formatTime } from '../../../utils/helpers';
import './ranking.css';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import bg from '../../assets/ranking/fondo-ranking.svg';
import logoGebro from '../../assets/logo-gebrov4.svg';
import logoLexic from '../../assets/logo-lexic.png';

const RankingDialog = (props) => {
	const { open, setOpen, data, title } = props;
	return (
		<Dialog
			fullScreen
			open={open}
			className='bgBlue'
			onClose={() => setOpen(null)}
			aria-labelledby='ranking-dialog'>
			<DialogTitle disableTypography className='bgBlue dialog-title'>
				<CloseIcon
					className='close-dialog-icon'
					onClick={() => setOpen(null)}
				/>
			</DialogTitle>
			<DialogContent className='bgBlue'>
				<div>
					<div className='container-fluid'>
						<div className='row text-center pt-5 justify-content-center'>
							<h1 className='title6'>{title}</h1>
						</div>
						<div className='row text-center my-25 justify-content-center ranking-row'>
							<div className='col-12 col-sm-3 d-flex justify-content-center align-items-center'>
								<div className='position-relative align-self-end'>
									<div className='rankingText title8 txtWhite'>
										{formatScore(data[2], open)}
									</div>
									<div className='rankingText-time title8 txtWhite'>
										{formatTime(data[2], open)}
									</div>
									<div className='figure-3rd'>
										<div className='figure-line'></div>
									</div>
									<div className='position-relative mt-4'>
										<div className='teamfoto-small'>
											{data[2]?.image && (
												<img src={`${SERVER_URL}/${data[2]?.image}`} alt='' />
											)}
										</div>
									</div>
									<div className='bigTextB txtWhite mt-4 mb-1'>
										{data[2]?.fullName || '--'}
									</div>
									<div className='separator-ranking m-auto'></div>
									<div className='bigTextB txtWhite mt-1 '>
										{`${
											data[2]?.tableNum ? 'Table ' + data[2].tableNum : '--'
										}`}
									</div>
								</div>
							</div>
							<div className='col-12 col-sm-3 d-flex justify-content-center align-items-center'>
								<div className='position-relative align-self-end'>
									<div className='rankingText title8 txtWhite'>
										{formatScore(data[0], open)}
									</div>
									<div className='rankingText-time title8 txtWhite'>
										{formatTime(data[0], open)}
									</div>
									<div className='figure-1st'>
										<div className='figure-line'></div>
									</div>
									<div className='position-relative mt-4'>
										<div className='teamfoto-small'>
											{data[0]?.image && (
												<img src={`${SERVER_URL}/${data[0]?.image}`} alt='' />
											)}
										</div>
									</div>
									<div className='bigTextB txtWhite mt-4 mb-1'>
										{data[0]?.fullName || '--'}
									</div>
									<div className='separator-ranking m-auto'></div>
									<div className='bigTextB txtWhite mt-1 '>
										{`${
											data[0]?.tableNum ? 'Table ' + data[0].tableNum : '--'
										}`}
									</div>
								</div>
							</div>
							<div className='col-12 col-sm-3 d-flex justify-content-center align-items-center'>
								<div className='position-relative align-self-end'>
									<div className='rankingText title8 txtWhite'>
										{formatScore(data[1], open)}
									</div>
									<div className='rankingText-time title8 txtWhite'>
										{formatTime(data[1], open)}
									</div>
									<div className='figure-2nd'>
										<div className='figure-line'></div>
									</div>
									<div className='position-relative mt-4'>
										<div className='teamfoto-small'>
											{data[1]?.image && (
												<img src={`${SERVER_URL}/${data[1]?.image}`} alt='' />
											)}
										</div>
									</div>
									<div className='bigTextB txtWhite mt-4 mb-1'>
										{data[1]?.fullName || '--'}
									</div>
									<div className='separator-ranking m-auto'></div>
									<div className='bigTextB txtWhite mt-1'>
										{`${
											data[1]?.tableNum ? 'Table ' + data[1].tableNum : '--'
										}`}
									</div>
								</div>
							</div>
						</div>
					</div>
					<img className='background-image' src={bg} alt='' />
				</div>
			</DialogContent>
			<DialogActions className='bgBlue'>
				<div className='d-flex justify-content-between legalGebro w-100 position-absolute'>
					<div className='d-flex flex-column align-items-start pl-10'>
						<div className='mb-2 smallTextB'>Entidad organizadora:</div>
						<img src={logoGebro} alt='20 years Gebro Pharma' />
					</div>
					<div className='d-flex flex-column align-items-end pr-10'>
						<div className='mb-2 smallTextB'>Desarrollado por:</div>
						<img src={logoLexic} alt='20 years Gebro Pharma' className='ms-5' />
					</div>
				</div>
				<div className='yellowBottom'></div>
			</DialogActions>
		</Dialog>
	);
};
export default RankingDialog;
