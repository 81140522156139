import { API, authClient } from '../index'

export const login = (email, password) => {
  const body = { email, password }
  return authClient().post(`${API}/user/signin`, body)
}

export const register = (body) => {
  return authClient().post(`${API}/user/signup`, body)
}

export const logout = () => {
  return authClient().get(`${API}/user/logout`)
}

export const getCredentials = () => {
  return authClient().get(`${API}/user/credentials`)
}

export const refreshTokens = () => {
  return authClient().get(`${API}/user/refresh`)
}