import axios from "axios";
export * from "./logger.js";
export * from "./index.js";

export const baseClient = () => {
	return axios.create();
};

export const authClient = () => {
	return axios.create({
		withCredentials: true,
	});
};

const PRO =
	process.env.NODE_ENV === "production" || process.env.NODE_ENV === "pro";

export const SERVER_URL = PRO
	? "https://api.spacces.com:4017"
	: "http://localhost:4001";

export const FRONT_URL = "https://www.mtxperiencegame.com";

export const API = `${SERVER_URL}/api`;
