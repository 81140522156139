import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls';
import {
	ResultsTable,
	QuizResultsTable,
	resultFormatter,
} from '../components/tables/table';
import Select from 'react-select';
import { getGames } from '../../api/game';
import { getScenes } from '../../api/scene';
import { getAnswers } from '../../api/answer';
import { getQuizzes } from '../../api/quiz';
import { getGroupsByGame } from '../../api/group';
import { FormHelperText, Button } from '@material-ui/core';
import { alertError } from '../../utils/logger';
import { shallowEqual, useSelector } from 'react-redux';

function getClinicCases(game) {
	let data = [];
	game.clinicCases.forEach((cliniccase) => {
		data.push(cliniccase.fullName);
	});
	return data;
}

function getGameQuizzesData(game, groups, scenarios, quizzes, answers) {
	let returns = {};
	game.clinicCases.forEach((cliniccase, indexClinic) => {
		let data = [];
		scenarios
			.filter((scenario) => {
				return scenario.cliniccase._id === cliniccase._id;
			})
			.forEach((scenario) => {
				let quizzesRes = [];
				quizzes
					.filter(
						(quiz) => quiz.scenario._id === scenario._id && !quiz.supertest
					)
					.forEach((quiz) => {
						let quizRes = {};
						groups.forEach((group, indexGroup) => {
							let answer = answers.find(
								(answer) =>
									answer.quiz === quiz._id && answer.group === group._id
							);
							console.log(answer);
							if (answer) {
								quizRes[`group${indexGroup + 1}`] = {
									pts: answer.score,
									time: answer.time,
									name: group.fullName,
								};
								if (answer.hasOwnProperty('correct'))
									quizRes[`group${indexGroup + 1}`].correct = answer.correct;
							}
						});
						quizRes.quiz = quiz.fullName;
						quizzesRes.push(quizRes);
					});
				data.push(quizzesRes);
			});
		returns[`clinicCase${indexClinic + 1}`] = data;
	});
	return returns;
}

function getGameData(game, groups, scenarios, answers) {
	let returns = {};
	game.clinicCases.forEach((cliniccase, indexClinic) => {
		let data = [];

		scenarios
			.filter((scenario) => {
				return scenario.cliniccase._id === cliniccase._id;
			})
			.forEach((scenario, indexScenario) => {
				let elem = {};
				elem.scenario = `Scenario ${indexScenario + 1}`;
				elem.scenarioName = scenario.fullName;
				groups.forEach((group, indexGroup) => {
					let groupAnswers = answers.filter((answer) => {
						return (
							answer.scenario === scenario._id &&
							answer.group === group._id &&
							!answer.hasOwnProperty('correct')
						);
					});
					let result = null;
					if (groupAnswers.length)
						result = groupAnswers?.reduce((a, b) => ({
							score: a.score + b.score,
							time: a.time + b.time,
						}));
					if (result)
						elem[`group${indexGroup + 1}`] = {
							pts: result.score,
							time: result.time,
							name: group.fullName,
						};
				});
				data = data.concat(elem);
			});
		returns[`clinicCase${indexClinic + 1}`] = data;
	});
	return returns;
}

export function ResultsPage() {
	const [games, setGames] = useState([]);
	const [groups, setGroups] = useState([]);
	const [quizzes, setQuizzes] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [scenarios, setScenarios] = useState([]);
	const [quizzesData, setQuizzesData] = useState([]);
	const [actualGame, setActualGame] = useState({ fullName: '' });
	const [clinicCases, setClinicCases] = useState([]);
	const [data, setData] = useState({});
	const [isExpanded, setIsExpanded] = useState([false, false]);
	const [expandedRowsCase1, setExpandedRowsCase1] = useState([]);
	const [expandedRowsCase2, setExpandedRowsCase2] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function styleFormatter(cell, row) {
		if (cell) {
			let max = true;
			let min = true;
			for (let i = 1; i < 10; ++i) {
				if (
					row[`group${i}`]?.pts > cell.pts ||
					(row[`group${i}`]?.pts === cell.pts &&
						row[`group${i}`]?.time < cell.time)
				) {
					max = false;
				}

				if (
					row[`group${i}`]?.pts < cell.pts ||
					(row[`group${i}`]?.pts === cell.pts &&
						row[`group${i}`]?.time > cell.time)
				) {
					min = false;
				}
				if (!min && !max) {
					return;
				}
			}
			if (max) return { backgroundColor: '#c8e6c9' };
			else if (min) return { backgroundColor: '#e6c8c8' };
		}
	}

	function groupFormatter(column, colIndex) {
		return <div>{groups[colIndex - 1]?.fullName || column.text}</div>;
	}

	const handleExpandAll = (index) => {
		if (isExpanded[index]) {
			if (index === 0) {
				setExpandedRowsCase1([]);
				setIsExpanded([false, isExpanded[1]]);
			} else {
				setExpandedRowsCase2([]);
				setIsExpanded([isExpanded[1], false]);
			}
		} else {
			if (index === 0) {
				setExpandedRowsCase1(
					Array.from({ length: 8 }, (_, i) => `Scenario ${i + 1}`)
				);
				setIsExpanded([true, isExpanded[1]]);
			} else {
				setExpandedRowsCase2(
					Array.from({ length: 8 }, (_, i) => `Scenario ${i + 1}`)
				);
				setIsExpanded([isExpanded[1], true]);
			}
		}
	};

	const handleOnExpandCase1 = (row, isExpand, rowIndex, e) => {
		if (isExpand) {
			setExpandedRowsCase1([...expandedRowsCase1, row.scenario]);
		} else {
			setExpandedRowsCase1(expandedRowsCase1.filter((x) => x !== row.scenario));
		}
	};
	const handleOnExpandCase2 = (row, isExpand, rowIndex, e) => {
		if (isExpand) {
			setExpandedRowsCase2([...expandedRowsCase2, row.scenario]);
		} else {
			setExpandedRowsCase2(expandedRowsCase2.filter((x) => x !== row.scenario));
		}
	};

	const expandRowCase1 = {
		renderer: (row) => (
			<div style={{ margin: '10px' }}>
				<b>{`${row.scenarioName} | Quizzes Results`}</b>
				<QuizResultsTable
					data={quizzesData.clinicCase1[row.scenario.match(/\d+/)[0] - 1]}
					columns={quizColumns}
				/>
			</div>
		),
		showExpandColumn: true,
		onlyOneExpanding: true,
		expanded: expandedRowsCase1,
		onExpand: handleOnExpandCase1,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			if (isAnyExpands) {
				return <b>-</b>;
			}
			return <b>+</b>;
		},
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
				return <b>-</b>;
			}
			return <b>...</b>;
		},
	};
	const expandRowCase2 = {
		renderer: (row) => (
			<div style={{ margin: '10px' }}>
				<b>{`${row.scenario} Quizzes results`}</b>
				<QuizResultsTable
					data={quizzesData.clinicCase2[row.scenario.match(/\d+/)[0] - 1]}
					columns={quizColumns}
				/>
			</div>
		),
		showExpandColumn: true,
		onlyOneExpanding: true,
		expanded: expandedRowsCase2,
		onExpand: handleOnExpandCase2,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			if (isAnyExpands) {
				return <b>-</b>;
			}
			return <b>+</b>;
		},
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
				return <b>-</b>;
			}
			return <b>...</b>;
		},
	};

	const headerClinicCase = (cliniccase, index) => (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
			}}>
			<h4
				style={{
					textAlign: 'center',
					color: 'rgb(24, 28, 50)',
					border: '1px solid rgb(215, 218, 231)',
					borderWidth: '0px 0px 2px 0px',
					padding: '0.5em',
				}}>
				{cliniccase[index]}
			</h4>
			<Button
				style={{ marginLeft: 'auto' }}
				variant='outlined'
				color='secondary'
				onClick={() => handleExpandAll(index)}>
				{!isExpanded[index] ? 'Expand All' : 'Collapse All'}
			</Button>
		</div>
	);

	const columns = [
		{ dataField: 'scenarioName', text: 'Scenario' },
		{
			dataField: 'group1',
			text: 'Group 1',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group2',
			text: 'Group 2',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group3',
			text: 'Group 3',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group4',
			text: 'Group 4',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group5',
			text: 'Group 5',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group6',
			text: 'Group 6',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group7',
			text: 'Group 7',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group8',
			text: 'Group 8',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group9',
			text: 'Group 9',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: 'group10',
			text: 'Group 10',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
	];
	const quizColumns = [
		{ dataField: 'quiz', text: 'Quiz', headerAlign: 'center' },
		{
			dataField: 'group1',
			text: 'Group 1',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group2',
			text: 'Group 2',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group3',
			text: 'Group 3',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group4',
			text: 'Group 4',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group5',
			text: 'Group 5',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group6',
			text: 'Group 6',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group7',
			text: 'Group 7',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group8',
			text: 'Group 8',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group9',
			text: 'Group 9',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: 'group10',
			text: 'Group 10',
			headerAlign: 'center',
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
	];

	useEffect(() => {
		if (actualGame._id) {
			getGroupsByGame(actualGame._id)
				.then((resGroups) => {
					if (resGroups.status === 200) {
						setGroups(resGroups.data);
						setQuizzesData(
							getGameQuizzesData(
								actualGame,
								resGroups.data,
								scenarios,
								quizzes,
								answers
							)
						);
						setData(
							getGameData(actualGame, resGroups.data, scenarios, answers)
						);
						setClinicCases(getClinicCases(actualGame));
						setRefresh(false);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not get game groups.',
					});
				});
		}
	}, [actualGame]);

	useEffect(() => {
		getQuizzes()
			.then((res) => {
				if (res.status === 200) {
					setQuizzes(res.data);
					getAnswers()
						.then((res) => {
							if (res.status === 200) {
								setAnswers(res.data);
								getScenes()
									.then((res) => {
										if (res.status === 200) {
											setScenarios(res.data);
											getGames()
												.then((res) => {
													if (res.status === 200) {
														setGames(res.data);
														if (user.game) {
															setActualGame(
																res.data.filter((elem) => {
																	return elem._id === user.game;
																})[0]
															);
														}
														setRefresh(false);
													}
												})
												.catch((error) => {
													alertError({
														error: error,
														customMessage: 'Could not get games.',
													});
												});
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage: 'Could not get scenarios.',
										});
									});
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: 'Could not get answers.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get quizzes.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader
					title={`${
						user.game ? actualGame.fullName : 'Games list'
					}`}></CardHeader>
				<CardBody>
					{!user.game && (
						<>
							<Select
								options={games?.map((game) => ({
									label: game.fullName,
									value: game._id,
								}))}
								value={games
									?.filter((game) => game._id === actualGame._id)
									.map((game) => ({
										label: game.fullName,
										value: game._id,
									}))}
								onChange={(value) => {
									setActualGame(
										games.filter((game) => game._id === value.value)[0]
									);
								}}
								isClearable
								isSearchable
								placeholder={`Select game...`}
								className='mb-3 mt-3'
							/>
							<FormHelperText>Select a Game</FormHelperText>
						</>
					)}
					{clinicCases.length ? (
						<>
							<ResultsTable
								data={data.clinicCase1}
								columns={columns}
								header={headerClinicCase(clinicCases, 0)}
								expandRow={expandRowCase1}
							/>
							<ResultsTable
								data={data.clinicCase2}
								columns={columns}
								header={headerClinicCase(clinicCases, 1)}
								expandRow={expandRowCase2}
							/>
						</>
					) : (
						<></>
					)}
				</CardBody>
			</Card>
		</>
	);
}
